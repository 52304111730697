import React from "react"

// Libraries
import { graphql } from "gatsby"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Seo from "components/seo"
import Container from "components/container"
import styled from "styled-components"
import { ButtonLink } from "components/buttons"

const StyledContainer = styled(Container)`
	max-width: 1110px;
  	overflow: hidden;
  	padding: 0 10px;
  	margin: 60px auto;
  	
      ${breakpoint.small`
  		padding: 0;
      `}
  	
	  h1 {
	  	font-size: 32px;
		margin: 0 auto;
		text-align: center;
      	margin-inline: 5px;
		
      ${breakpoint.small`
        text-align: left;
      	margin-inline: 35px;
      `}
	  }

	p {
		text-align: center;
      	margin-inline: 5px;
      	line-height: 1.2em;
		
      ${breakpoint.small`
      	margin-inline: 35px;
        text-align: left;
      `}
	}

	.pubdate {
		font-size: 13px;
		
      ${breakpoint.small`
      	font-size: 15px;
      `}
	}

	.categoryName {
		color: ${colors.purpleDark};
		font-size: 19px;
		text-transform: uppercase;
		font-weight: 700;
		margin-bottom: 10px;
		text-align: center;
		
      ${breakpoint.small`
        text-align: left;
      `}
	}

  	Img {
  		margin-top: 30px;
  	}

	 iframe {
		display: flex;
		min-width: 93%;
		max-width: 96%;
		min-height: 300px;
		max-height: 300px;
		height: 100%;
		margin: 0 auto;
		justify-content: center;
		background: ${colors.black};


      ${breakpoint.small`
		min-height: 450px;
		text-align: left;
      	margin: 20px 35px;
      `}
		
      ${breakpoint.medium`
		min-height: 600px;
      `}
  	}

  }
  
  
      ${ButtonLink} {
      		display: flex;
			width: auto;
			margin: 20px;

      ${breakpoint.small`
			width: 220px;
			margin: 20px 35px;
      `}
    }

	.fullDescription {
	
	p { 
		margin-block: 10px;
		padding-bottom: 20px;
		line-height: 1.3em; 
	}
	
    a {
      color: ${colors.orange};

      &:hover {
        color: ${colors.purpleDark};
      }
    }

}

`

export default function VideoPost({ data }) {
	const post = data.allWpVideo.nodes[0]
	return (
		<React.Fragment>
			<StyledContainer>
				{post.videos.category === "how_to" ? (
					<p className="categoryName">How To Video</p>
				)
					: null
				}
				{post.videos.category === "appearances" ? (
					<p className="categoryName">Media Coverage</p>
				)
					: null
				}
				{post.videos.category === "educational" ? (
					<p className="categoryName">Educational</p>
				)
					: null
				}
				{post.videos.category === "visby_answers" ? (
					<p className="categoryName">Visby Answers</p>
				)
					: null
				}
				{post.videos.category === "visby_onsite" ? (
					<p className="categoryName">Visby On-Site</p>
				)
					: null
				}
				<h1>{post.title}</h1>
				<p>{post.videos.description}<br /><span className="pubdate">{post.videos.publishDate}</span></p>
				<iframe
					id={post.slug}
					title={post.title}
					src={post.videos.url + '?autoplay=1'}
					allow="autoplay; accelerometer; encrypted-media; gyroscope; picture-in-picture"
					webkitallowfullscreen="true"
					mozallowfullscreen="true"
				></iframe>
				<div
					className="fullDescription"
					dangerouslySetInnerHTML={{ __html: post.videos.fullDescription }}
				>
				</div>
				{post.videos.category === "appearances" ? (
					<ButtonLink backgroundcolor="orange" to="/resources/media-coverage/">
						View All Media Coverage
					</ButtonLink>
				)
					:
					(
						<ButtonLink backgroundcolor="orange" to="/resources/videos/">
							View All Videos
						</ButtonLink>
					)
				}
			</StyledContainer>
		</React.Fragment>
	)
}
export const query = graphql`
  query($slug: String!) {
    allWpVideo(filter: { slug: { eq: $slug } }) {
		nodes {
			id
			title
			slug
			videos {
				description
				url
				category
				order
				publishDate
				fullDescription
				videoImage {
					localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(width: 600)
					}
				}
          }
        }
      }
    }
  }
`

export const Head = ({ data: { allWpVideo: post } }) => {
	return (
		<Seo
			article
			title={post.title}
			description={post.nodes[0].videos.description}
			image={post.nodes[0].videos.videoImage.localFile.publicURL}
			videoURL={post.nodes[0].videos.url}
		/>
	)
}
